import React, { VoidFunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Isin } from "../../data/dataPublic";
import { BaseProps, baseStyles } from "../../utils/ComponentBaseProps";
import { getLanguage, Language } from "../../utils/Localization";
import { Grid } from "../layout/grid/Grid";
import { Query } from "../../utils/query";
import "./FundDocuments.scss";

interface Props extends BaseProps {
  isin: Isin;
}

type QueryData = {
  lfFundDocuments: {
    data: {
      attributes: {
        locale: Language;
        isin: string;
        title: string;
        lf_documents: {
          data: {
            attributes: {
              title: string;
              file: {
                data: {
                  attributes: {
                    url: string;
                  };
                };
              };
            };
          }[];
        };
      };
    }[];
  };
} & Record<Language, { data: { attributes: { title: string } } }> &
  Record<
    "enDoc" | "svDoc",
    {
      data: {
        attributes: {
          lf_documents: {
            data: {
              attributes: {
                title: string;
                file: {
                  data: {
                    attributes: {
                      url: string;
                    };
                  };
                };
              };
            }[];
          };
        };
      };
    }
  >;

export const FundDocuments: VoidFunctionComponent<Props> = ({
  isin,
  ...props
}) => {
  // TODO: Fix request filtering on document isin so that pagination is not needed
  const data = useStaticQuery<Query<QueryData>>(graphql`
    {
      strapi {
        lfFundDocuments(locale: "all", pagination: { pageSize: 100 }) {
          data {
            attributes {
              locale
              isin
              lf_documents {
                data {
                  id
                  attributes {
                    title
                    file {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        en: lfFundDocumentContainer(locale: "en") {
          data {
            attributes {
              title
            }
          }
        }
        sv: lfFundDocumentContainer(locale: "sv") {
          data {
            attributes {
              title
            }
          }
        }
        enDoc: lfGeneralDocument(locale: "en") {
          data {
            attributes {
              lf_documents {
                data {
                  id
                  attributes {
                    title
                    file {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        svDoc: lfGeneralDocument(locale: "sv") {
          data {
            attributes {
              locale
              lf_documents {
                data {
                  id
                  attributes {
                    title
                    file {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)?.strapi;

  const title = data[getLanguage()].data?.attributes?.title;

  const fundDocuments = data?.lfFundDocuments?.data.find(
    (doc) =>
      doc.attributes.locale === getLanguage() && doc.attributes.isin === isin,
  )?.attributes.lf_documents.data;

  const generalDocuments =
    data?.[`${getLanguage()}Doc`]?.data?.attributes.lf_documents.data;

  if (
    (!fundDocuments || fundDocuments.length === 0) &&
    (!generalDocuments || generalDocuments.length === 0)
  ) {
    return null;
  }

  return (
    <div {...baseStyles(props, "fund-documents")}>
      <div className="full-width-padding">
        <h3>{title}</h3>
        <Grid row>
          {generalDocuments?.map(({ attributes: { title, file } }) => (
            <Grid key={title} col xs={12}>
              <a href={file.data.attributes.url} download>
                <span>{title}</span>
              </a>
            </Grid>
          ))}
          {fundDocuments?.map(({ attributes: { title, file } }) => (
            <Grid key={title} col xs={12}>
              <a href={file.data.attributes.url} download>
                <span>{title}</span>
              </a>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
