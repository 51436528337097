import { cache } from "./Cache";

export class ServerError<T> extends Error {
  public status: number;
  public statusText: string;
  public data: T;

  constructor(status: number, statusText: string, data: T) {
    super(`Server error - ${status}: ${statusText}`);
    this.status = status;
    this.statusText = statusText;
    this.data = data;
  }
}

interface HandleResponse {
  response: Response;
  deleteCacheUrl?: string;
}

async function handleResponse<T>(opts: HandleResponse): Promise<T> {
  const { response, deleteCacheUrl } = opts;

  const contentType = response.headers.get("content-type");
  let data;

  if (contentType && contentType.indexOf("application/json") !== -1) {
    data = response.json();
  } else {
    data = response.text();
  }

  if (!response.ok) {
    const resolvedData = await data;

    if (deleteCacheUrl) {
      cache.delete(deleteCacheUrl);
    }

    throw new ServerError<T>(
      response.status,
      response.statusText,
      resolvedData,
    );
  }

  return data;
}

const appId = generateUID();

export class API {
  static get<T>(
    url: string,
    refreshCache: boolean = false,
    options: RequestInit = {},
  ): Promise<T> {
    const cachedResult = cache.get(url);
    if (cachedResult && !refreshCache) {
      return cachedResult;
    }

    const promise = fetch(API.getBaseUrl(url) + url, {
      headers: undefined,
      ...options,
    }).then((response) => handleResponse<T>({ response, deleteCacheUrl: url }));

    return cache.set(url, promise);
  }

  static postFormData<T>(
    url: string,
    data: FormData,
    options: RequestInit = {},
  ): Promise<T> {
    return fetch(API.getBaseUrl(url) + url, {
      method: "POST",
      headers: undefined,
      body: data,
      ...options,
    }).then((response) => handleResponse<T>({ response }));
  }

  static post<T>(
    url: string,
    data?: Object,
    options: RequestInit = {},
  ): Promise<T> {
    return fetch(API.getBaseUrl(url) + url, {
      method: "POST",
      headers: undefined,
      body: JSON.stringify(data),
      ...options,
    }).then((response) => handleResponse<T>({ response }));
  }

  static put<T>(
    url: string,
    data?: Object,
    options: RequestInit = {},
  ): Promise<T> {
    return fetch(API.getBaseUrl(url) + url, {
      method: "PUT",
      headers: undefined,
      body: JSON.stringify(data),
      ...options,
    }).then((response) => handleResponse<T>({ response }));
  }

  static delete<T>(
    url: string,
    data?: Object,
    options: RequestInit = {},
  ): Promise<T> {
    return fetch(API.getBaseUrl(url) + url, {
      method: "DELETE",
      headers: undefined,
      body: JSON.stringify(data),
      ...options,
    }).then((response) => handleResponse<T>({ response }));
  }

  private static getBaseUrl(url: string) {
    if (url.indexOf("http") === 0) {
      /**
       * Urls that start with http probably have a host in it
       */
      return "";
    }
    return process.env.GATSBY_API || "http://localhost:9876";
  }
}

/**
 * https://stackoverflow.com/a/2117523
 */
function generateUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
