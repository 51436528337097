import React, { FunctionComponent } from "react";
import cx from "classnames";
// import { Check, Close } from "@mui/icons-material";
import "./Snackbar.scss";

export type SNACKBAR_TYPES = "info" | "success" | "warning" | "error";

interface Props {
  type: SNACKBAR_TYPES;
  icon?: boolean;
  children: React.ReactNode;
}

export const Snackbar: FunctionComponent<Props> = ({
  type,
  icon = false,
  children,
}) => {
  const classes = cx("lysa-ui-snackbar", `lysa-snackbar-${type}`);

  let iconComponent = null;
  if (icon && type === "info") {
    iconComponent = (
      <span className="icon-wrapper">
        {/* <Close style={{ width: 16, height: 16, color: "#1840e3" }} /> */}
      </span>
    );
  } else if (icon && type === "success") {
    iconComponent = (
      <span className="icon-wrapper">
        {/* <Check style={{ width: 16, height: 16, color: "#1840e3" }} /> */}
      </span>
    );
  } else if (icon && type === "warning") {
    iconComponent = (
      <span className="icon-wrapper">
        {/* <Close style={{ width: 16, height: 16, color: "#FDDE4A" }} /> */}
      </span>
    );
  } else if (icon && type === "error") {
    iconComponent = (
      <span className="icon-wrapper">
        {/* <Close style={{ width: 16, height: 16, color: "#FF7576" }} /> */}
      </span>
    );
  }

  return (
    <div className={classes}>
      {iconComponent}
      {/* We wrap children in a div so they're not affected by flexbox */}
      <div>{children}</div>
    </div>
  );
};
