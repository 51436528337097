import React from "react";
import { Spinner } from "../spinner/Spinner";
import "./Pending.scss";

export function Pending() {
  return (
    <div className="retry-pending">
      <Spinner />
    </div>
  );
}
