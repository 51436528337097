import React, {
  useCallback,
  useEffect,
  useState,
  VoidFunctionComponent,
} from "react";
import { Layout } from "../../components/layout/Layout";
import { Seo } from "../../components/seo";
import { useLocation } from "@gatsbyjs/reach-router";
import { dataIndex, Isin, Nav } from "../../data/dataPublic";
import { Retry, Status } from "../../components/retry/Retry";
import { FundDocuments } from "../../components/fundDocuments/FundDocuments";
import { graphql } from "gatsby";
import { Query } from "../../utils/query";
import "./fund.scss";

interface QueryData {
  lfPageFund: {
    data: {
      attributes: {
        itemFundName: string;
        itemNav: string;
        itemCurrency: string;
        itemDate: string;
        itemIsin: string;
      };
    };
  };
}

const Fund: VoidFunctionComponent<{ data: Query<QueryData> }> = ({ data }) => {
  const messages = data.strapi.lfPageFund.data.attributes;
  const location = useLocation();
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const [fund, setFund] = useState<Nav>();

  const search = new URLSearchParams(location.search);
  const isin = search.get("isin") as Isin | null;

  const load = useCallback(() => {
    if (!isin) {
      setStatus(Status.ERROR);
      return;
    }

    setStatus(Status.PENDING);
    dataIndex
      .getFunds()
      .then((holdingDetailed) => {
        setFund(
          holdingDetailed.find((fund) => fund.isin === isin) ?? undefined,
        );
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    load();
  }, []);

  if (!isin) {
    return null;
  }

  return (
    <Layout className="fund" fullWidth>
      <Seo title={`Lysa Fonder${fund ? ` | ${fund?.name}` : ""}`} />
      <Retry retry={load} status={status}>
        <div className="fund--container">
          <h1>{fund?.name}</h1>
          <dl>
            <dt>{messages.itemIsin}</dt>
            <dd>{fund?.isin}</dd>

            <dt>{messages.itemDate}</dt>
            <dd>{fund?.date}</dd>

            <dt>{messages.itemNav}</dt>
            <dd>{fund?.price}</dd>

            <dt>{messages.itemCurrency}</dt>
            <dd>{fund?.currency}</dd>
          </dl>
        </div>
        {/* <GraphCard isin={isin} />
        <GicsList
          underlyingHoldings={holdingDetailed?.underlyingStocksHoldings}
        />
        <HeatMap
          underlyingHoldings={holdingDetailed?.underlyingStocksHoldings}
        />
        <HoldingsSearch
          underlyingHoldings={holdingDetailed?.underlyingStocksHoldings}
        /> */}

        <FundDocuments isin={isin} />
      </Retry>
    </Layout>
  );
};

export default Fund;

export const query = graphql`
  query ($locale: STRAPI_I18NLocaleCode!) {
    strapi {
      lfPageFund(locale: $locale) {
        data {
          attributes {
            itemFundName
            itemNav
            itemCurrency
            itemDate
            itemIsin
          }
        }
      }
    }
  }
`;
