import { API } from "../network/API";
import { GICSCode } from "../utils/gics/GICS";

export type Isin = string & { isIsin: true };
export type IndexId = string & { isIndexId: true };
export type Currency = string & { isCurrency: true };
export type DateString = string & { isDate: true };

export interface Region {
  countryCode: string;
  holdingPercent: number;
}

export interface Nav {
  currency: string;
  date: string;
  isin: Isin;
  name: string;
  price: number;
}

export type IndexItem = {
  holdingCurrency: Currency;
  inceptionDate: string;
  indexId: IndexId;
  name: string;
  price: number;
  date: DateString;
  time: string;
};

export interface IndexHoldings {
  date: DateString;
  suppressionExpiration: DateString;
  releaseTopHoldingDate: DateString;
  holding: IndexHolding[];
}

export interface IndexHolding {
  isin: Isin;
  currency: Currency;
  countryCode: string;
  name: string;
  shares: number;
  marketValue: number;
  gicsIndustryId: GICSCode;
}

export interface Index {
  indexId: IndexId;
  inceptionDate: string;
  name: string;
  holdingCurrency: Currency;
  holdings: IndexHoldings[];
}

export interface IndexPrice {
  closingPrice: number;
  closingDate: string;
}

export const dataIndex = {
  getFunds: () => {
    return API.get<Nav[]>("/public/nav/latest");
  },
};
