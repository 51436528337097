import React from "react";
import { Snackbar } from "../snackbar/Snackbar";
import { Button } from "../button/Button";
import { graphql, useStaticQuery } from "gatsby";
import { getLanguage } from "../../utils/Localization";
import { QueryLanguage } from "../../utils/query";
import "./Error.scss";

interface Props {
  retry?: () => void;
  text?: React.ReactNode | string;
  buttonText?: React.ReactNode | string;
}

interface QueryData {
  attributes: {
    ouch: string;
    text: string;
    button: string;
  };
}

export const Error: React.FC<Props> = (props) => {
  const messages = useStaticQuery<QueryLanguage<QueryData>>(graphql`
    query {
      strapi {
        en: lfError(locale: "en") {
          data {
            attributes {
              ouch
              text
              button
            }
          }
        }
        sv: lfError(locale: "sv") {
          data {
            attributes {
              ouch
              text
              button
            }
          }
        }
      }
    }
  `)?.strapi[getLanguage()].data.attributes;

  const ouch = props.text ? null : <b>{messages.ouch}</b>;
  const text = props.text || (
    <span>
      {ouch} {messages.text}
    </span>
  );
  const buttonText = props.buttonText || messages.button;

  return (
    <div className="retry-error">
      <Snackbar type="error">
        {text}
        {props.retry ? (
          <div>
            <Button onClick={props.retry}>{buttonText}</Button>
          </div>
        ) : null}
      </Snackbar>
    </div>
  );
};
