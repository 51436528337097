import React, { useState, useEffect, useRef, useCallback } from "react";
import cx from "classnames";
import "./Button.scss";

type InferElement<T> = T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T] extends React.DetailedHTMLProps<
      React.HTMLAttributes<any>,
      infer Elem
    >
    ? Elem
    : never
  : HTMLElement;

export interface ButtonProps<C extends React.ElementType = "button"> {
  children: React.ReactNode | React.ReactNode[];
  type?: "button" | "submit" | "reset";
  onClick?(event: React.MouseEvent<InferElement<C>, MouseEvent>): void;
  className?: string;
  active?: boolean;
  disabled?: boolean;
  block?: boolean;
  "data-test-id"?: string;
  ariaLabel?: string;
  tabIndex?: number;
  size?: "mini" | "small" | "medium";
  variant?: "primary" | "secondary" | "negative";
  component?: C;
}

export type ButtonPropsWithComponent<
  C extends React.ElementType
> = ButtonProps<C> & React.ComponentPropsWithRef<C>;

function ButtonFunction<C extends React.ElementType = "button">(
  {
    children,
    type = "button",
    onClick,
    className,
    active,
    disabled,
    block,
    ariaLabel,
    "data-test-id": dataTestId,
    tabIndex,
    size = "medium",
    variant = "primary",
    component,
    ...props
  }: ButtonPropsWithComponent<C>,
  forwardedRef: React.RefObject<InferElement<C>>
) {
  const [isAnimating, setIsAnimating] = useState(false);
  const internalRef = useRef<InferElement<C>>(null);
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const classes = cx(
    className,
    "lysa-ui-button",
    "lysa-ripple-box",
    `button-${size}`,
    `button-${variant}`,
    { active: active },
    { disabled: disabled },
    { block: block },
    { "is-animating": isAnimating }
  );

  const Component: React.ElementType = component || "button";

  const activeRef = forwardedRef || internalRef;

  useEffect(() => {
    return () => {
      if (timeout && timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  useEffect(() => {
    const savedRef = activeRef;
    if (isAnimating && savedRef && savedRef.current) {
      savedRef.current.addEventListener("animationend", () =>
        setIsAnimating(false)
      );
    } else if (savedRef && savedRef.current) {
      savedRef.current.removeEventListener("animationend", () =>
        setIsAnimating(false)
      );
    }
    return () => {
      if (savedRef && savedRef.current) {
        savedRef.current.removeEventListener("animationend", () =>
          setIsAnimating(false)
        );
      }
    };
  }, [isAnimating, activeRef]);

  const onButtonClick = useCallback(
    (event: React.MouseEvent<InferElement<C>, MouseEvent>) => {
      if (disabled) {
        return;
      }

      setIsAnimating(false);

      if (activeRef?.current?.getBoundingClientRect) {
        const offsets = activeRef.current.getBoundingClientRect();
        activeRef.current.style.setProperty(
          "--x",
          (event.clientX - offsets.left).toString()
        );
        activeRef.current.style.setProperty(
          "--y",
          (event.clientY - offsets.top).toString()
        );
      }

      onClick?.(event);

      timeout.current = setTimeout(() => {
        // Force DOM reflow
        void (activeRef?.current as HTMLElement)?.offsetWidth;
        setIsAnimating(true);
      }, 0);
    },
    [activeRef, disabled, onClick]
  );

  return (
    <Component
      type={type}
      ref={activeRef}
      onClick={onButtonClick}
      className={classes}
      data-test-id={dataTestId}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      {...props}
    >
      {children}
      {!disabled && <span className="lysa-ripple" />}
    </Component>
  );
}

// https://fettblog.eu/typescript-react-generic-forward-refs/
// Redeclare forwardRef
declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.RefObject<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export const Button = React.forwardRef(ButtonFunction);
