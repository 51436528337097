import React from "react";
import cx from "classnames";
import { Error } from "./Error";
import { Pending } from "./Pending";
import "./Retry.scss";

export enum Status {
  PENDING = "is-pending",
  ERROR = "has-error",
  SUCCESS = "",
}

interface Props {
  retry?: () => void;
  text?: React.ReactNode | string;
  buttonText?: React.ReactNode | string;
  children: React.ReactNode | string;
  status: Status;
}

export const Retry: React.FC<Props> = (props) => {
  const { status, retry, text, buttonText, children } = props;

  const classname = cx("retry-wrapper", status);

  return (
    <div className={classname}>
      <Pending />
      <Error retry={retry} text={text} buttonText={buttonText} />
      <div className="retry">{children}</div>
    </div>
  );
};
